<script setup>
import { mapErrors } from '@/utilities';
import { useForm } from '@inertiajs/vue3';
import { inject, ref, watch } from 'vue';

import Button from '@/Components/Button/Button.vue';
import Rating from '@/Components/Input/Rating.vue';

const dayjs = inject('dayjs');
const route = inject('route');
import Modal from '@/Components/Popup/Modal.vue';

const props = defineProps({
    review: Object,
    showing: Boolean,
    hideNextButton: Boolean,
});

const emits = defineEmits(['closeModel']);

const form = useForm({
    shift_id: props.review.id,
    rating: props.review.rating,
    review: props.review.review,
});

watch(
    () => props.review,
    () => {
        form.review_id = props.review.id;
        form.rating = props.review.rating;
        form.review = props.review.review;
    }
);

let confirmDeletion = ref(false);

const sendForm = (andNext = false, preserveState = false) => {
    const url = route('staff.reviews.update', { id: props.review.id });

    form.transform((data) => ({
        ...data,
    })).patch(url, {
        preserveState: preserveState ? true : false,
        preserveScroll: true,
        onSuccess: () => (andNext ? emits('closeModel') : null),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};

const sendDeleteForm = (andNext = false, preserveState = false) => {
    const url = route('staff.reviews.destroy', { id: props.review.id });

    form.delete(url, {
        preserveState: preserveState ? true : false,
        preserveScroll: true,
        onSuccess: () => (andNext ? emits('closeModel') : null),
        onError: (errors) => (form.errors = mapErrors(errors)),
    });
};
</script>

<template>
    <div>
        <div>
            <Rating v-model:value="form.rating" :errors="form.errors.rating" :label="$t('Rating')" with-text />
            <div>
                <FormKit
                    v-model="form.review"
                    :errors="form.errors.review"
                    :label="$t('Remark')"
                    :value="form.review"
                    name="review"
                    type="textarea"
                />
            </div>
        </div>

        <div class="flex space-x-2">
            <div class="flex justify-end gap-4">
                <Button class="inline-flex w-fit" red @click="confirmDeletion = true">
                    <div class="gap-2 font-bold flex-center">
                        <span>{{ $t('Delete') }}</span>
                    </div>
                </Button>
            </div>
            <div
                :class="{
                    'opacity-50 pointer-events-none':
                        form.rating == props.review.rating && form.review == props.review.review,
                }"
                class="flex justify-end gap-4"
            >
                <Button class="inline-flex w-fit" orange @click="sendForm">
                    <div class="gap-2 font-bold flex-center">
                        <span>{{ $t('Save') }}</span>
                    </div>
                </Button>
            </div>
        </div>
    </div>

    <Modal v-model:open="confirmDeletion">
        <template #default>
            <h2 class="text-base font-bold">
                {{ $t('Are you sure you want to delete this {model}?', { model: $t('Review') }) }}
            </h2>
        </template>
        <template #button-bar>
            <Button class="inline-flex w-fit" red @click="sendDeleteForm">
                <div class="gap-2 font-bold flex-center">
                    <span>{{ $t('Delete') }}</span>
                </div>
            </Button>
            <Button white @click="confirmDeletion = false">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
